import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, Typography, Box, Radio, Modal, RadioGroup, Stack, Autocomplete, TextField } from '@mui/material';

import { MainContainer } from '../../../../components/MainContainer/MainContainer';
import { Container } from '../../../../components/Container/Container';
import { Menu } from '../Menu/Menu';
import RadiusButton from '../../../../components/RadiusButton';
import { getForPreview, verifyQuestion, updateById } from '../../../../services/questionService';
import { AuthContext } from '../../../../providers/auth';
import { ContentUploadErrorsModal } from '../ContentUploadErrorsModal/ContentUploadErrorsModal';
import { useToaster } from '../../../../providers/ToasterProvider';

import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import ARROW_LEFT from '../../../../assets/images/back.svg';
import NEXT from '../../../../assets/images/content-next.svg';
import DELETE_ICON from '../../../../assets/images/action-delete-white.svg';
import EDIT from '../../../../assets/images/content-edit.svg';

import './ContentPreview.scss';
import { LoadingButton } from '@mui/lab';

export const ContentPreview = () => {

    const params = useParams();
    const projectName = params?.projectName;
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useContext(AuthContext)
    const [drive, setDrive] = useState(null);
    const [course, setCourse] = useState(null);
    const [configuration, setConfiguration] = useState(null);
    const [language, setLanguage] = useState('');
    const [setNumber, setSetNumber] = useState(null);
    const [verifyQuestionNo, setVerifyQuestionNo] = useState(null);
    const [questionNo, setQuestionNo] = useState(null);
    const [question, setQuestion] = useState([]);
    const [maxQuestion, setMaxQuestion] = useState(null);
    const [modalData, setModalData] = useState({
        isVisible: false,
        questionId: null
    });
    const [errorModal, setErrorModal] = useState({
        isVisible: false,
        errors: []
    });
    const { addNotification } = useToaster();
    const [preview, setPreview] = useState({
        previewNo: '',
        inputNo: '',
        options: [],
    })
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const configuration = location?.state?.configuration;
        const language = location?.state?.language;
        const setNumber = location?.state?.setNumber;
        const drive = location?.state?.drive;
        const course = location?.state?.course;
        const questionNo = location?.state?.questionNo;
        const verifyQuestionNo = location?.state?.verifyQuestionNo;
        if (!configuration || !language || !setNumber || !drive || !course) {
            navigate(`/${projectName}/upload/content`, {
                state: {
                    configurationId: configuration?.id,
                    drive: drive,
                    course: course,
                }
            })
        } else {
            setDrive(drive);
            setCourse(course);
            setLanguage(language);
            setSetNumber(setNumber);
            setConfiguration(configuration);
            setMaxQuestion(configuration.questionsPerSet);
            setQuestionNo(questionNo);
            setVerifyQuestionNo(verifyQuestionNo)
            setPreview({
                previewNo: '',
                inputNo: '',
                options: Array.from({ length: configuration.questionsPerSet + 1 }, (_, i) => i ? i.toString() : '')
            })
        }
    }, [location]);

    useEffect(() => {
        if (configuration?.id && language && setNumber && questionNo) {
            const fetchQuestion = async () => {
                try {
                    const res = await getForPreview(configuration.id, language, setNumber, questionNo, 'L', verifyQuestionNo);
                    if (res.code === 'OK') {
                        setQuestion(res.data);
                    }
                } catch (error) {
                    console.log('There is an error while getting question', error);
                }
            }
            fetchQuestion();
        }
    }, [questionNo]);

    const onGoBack = () => {
        if (projectName) {
            navigate(`/${projectName}/upload`, {
                state: {
                    activeTab: 'content',
                    configurationId: configuration?.id,
                    drive: drive,
                    course: course,
                }
            });
        }
    }

    const handleRadioChange = (value) => {
        console.log(value);
    }
    const handlePrevious = () => {
        if (questionNo > 1 && projectName) {
            navigate(`/${projectName}/upload/content-preview`, {
                state: {
                    configuration: configuration,
                    language: language,
                    setNumber: setNumber,
                    drive: drive,
                    course: course,
                    questionNo: questionNo - 1,
                    verifyQuestionNo: null
                }
            });
        }
    }
    const handleNext = async () => {
        if (questionNo < maxQuestion) {
            let verifyQuestionNo = null;
            if (!question.isVerified) {
                verifyQuestionNo = questionNo;
            }
            if (projectName) {
                navigate(`/${projectName}/upload/content-preview`, {
                    state: {
                        configuration: configuration,
                        language: language,
                        setNumber: setNumber,
                        drive: drive,
                        course: course,
                        questionNo: questionNo + 1,
                        verifyQuestionNo: verifyQuestionNo
                    }
                });
            }
        }
    }
    const handleFinish = async () => {
        if (!question.isVerified) {
            try {
                const res = await verifyQuestion(question.id, user.id);
                if (res.code === 'OK') {
                    setQuestion({
                        ...question,
                        isVerified: true
                    });
                    onGoBack();
                }
            } catch (error) {
                console.log('There is an error while verifing question', error);
            }
        } else {
            onGoBack();
        }
    }
    const handleEdit = () => {
        setModalData({
            ...modalData,
            isVisible: true,
            questionId: question.id
        })
    }
    const handleFileDrop = (e) => {
        const inputFile = e.target.files?.[0];
        if (inputFile && inputFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            setModalData({
                ...modalData,
                file: inputFile
            });
        }
    }
    const handleSubmit = async () => {
        const { file } = modalData;
        if (!file || !question?.id) return
        setIsEditing(true);
        try {
            const body = new FormData();
            body.append('configurationId', configuration.id);
            body.append('set', setNumber);
            body.append('file', file);
            const res = await updateById(question.id, body);
            if (res.code === 'E_BAD_REQUEST') {
                setErrorModal({
                    isVisible: true,
                    errors: res.data.errors
                });
            } else {
                const res = await getForPreview(configuration.id, language, setNumber, questionNo, 'L');
                if (res.code === 'OK') {
                    setQuestion(res.data);
                }
                setModalData({
                    ...modalData,
                    isVisible: false,
                    file: null
                });
                addNotification('question updated successfully.', 'success');
            }
            const ele = document.getElementById('fileId');
            if (ele) {
                ele.value = '';
            }
        } catch (error) {
            console.log('There is an error while updating question', error);
        } finally {
            setIsEditing(false);
        }
    }
    const handleCloseEditModal = () => {
        setModalData({ ...modalData, file: null, isVisible: false });
        const ele = document.getElementById('fileId');
        if (ele) {
            ele.value = '';
        }
    }
    const handleRemoveFile = () => {
        const ele = document.getElementById('fileId');
        if (ele) {
            ele.value = '';
        }
        setModalData({
            ...modalData,
            file: null
        });
    }
    const setPreviewQuestionNo = (value) => {
        if (value) {
            setPreview({
                ...preview,
                previewNo: value,
            })
        }
    }
    const setPreviewQuestionInput = (value) => {
        setPreview({
            ...preview,
            inputNo: value
        });
    }
    const onView = () => {
        if (!preview.previewNo) {
            addNotification('Please select question no.')
        } else {
            setQuestionNo(Number(preview.previewNo));
        }
    }

    return (
        <MainContainer >
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{ fontWeight: 400 }}>Dashboard</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>Content Preview</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>

            <Container sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h5' component='h5' sx={{ mb: 2 }}>Content Preview</Typography>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                }}>
                    <Typography variant='h6' component='h6' sx={{ textTransform: 'capitalize' }}>{course?.name}</Typography>
                    <Typography variant='h6' component='h6' sx={{ textTransform: 'capitalize', ml: 'auto' }}>Set - {setNumber}</Typography>
                </Box>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    backgroundColor: 'background.base',
                    borderRadius: 4,
                    maxWidth: 'calc(100vw - 135px)',
                    mb: 1.5,
                    px: 1,
                    py: 1.5,
                    overflow: 'auto'
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                    }}>
                        <Typography variant='h6' component='h6' sx={{
                            fontSize: 16,
                            ml: 2,
                            width: 150,
                            color: 'primary.main'
                        }}
                        >Question Number</Typography>
                        <Typography variant='h6' component='h6' sx={{
                            fontSize: 16,
                            color: 'primary.main'
                        }}
                        >:&nbsp;&nbsp;{questionNo}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                    }}>
                        <Typography variant='h6' component='h6' sx={{
                            fontSize: 16,
                            ml: 2,
                            width: 150,
                            color: 'primary.main'
                        }}
                        >Section Name</Typography>
                        <Typography variant='h6' component='h6' sx={{
                            fontSize: 16,
                            color: 'primary.main'
                        }}
                        >:&nbsp;&nbsp;{question?.sectionName}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                    }}>
                        <Typography variant='h6' component='h6' sx={{
                            fontSize: 16,
                            ml: 2,
                            width: 150,
                            color: 'primary.main'
                        }}
                        >Level</Typography>
                        <Typography variant='h6' component='h6' sx={{
                            fontSize: 16,
                            color: 'primary.main'
                        }}
                        >:&nbsp;&nbsp;{question?.level}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                    }}>
                        <Typography variant='h6' component='h6' sx={{
                            fontSize: 16,
                            ml: 2,
                            width: 150,
                            color: 'primary.main'
                        }}
                        >Language</Typography>
                        <Typography variant='h6' component='h6' sx={{
                            fontSize: 16,
                            color: 'primary.main'
                        }}
                        >:&nbsp;&nbsp;{question?.language}</Typography>
                    </Box>
                    
                    {question.question && (
                        <img style={{ marginTop: '10px', marginBottom: '20px' }} src={question.question} alt='' />
                    )}
                    <RadioGroup name="answer" onChange={(e, value) => handleRadioChange(value)}>
                        {
                            question?.options?.map((option, index) => {
                                return <Box key={index} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    ml: 2,
                                    cursor: 'pointer'
                                }}>
                                    <Radio disabled id={`option_${index + 1}`} value={index + 1} disableRipple />
                                    <label htmlFor={`option_${index + 1}`} style={{ cursor: 'pointer' }}>
                                        <img key={index} src={option} alt='' />
                                    </label>
                                </Box>
                            })
                        }
                    </RadioGroup>
                </Box>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 1,
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}>
                        <Autocomplete
                            value={preview.previewNo}
                            onChange={(event, newValue) => {
                                setPreviewQuestionNo(newValue);
                            }}
                            inputValue={preview.inputNo}
                            onInputChange={(event, newInputValue) => {
                                setPreviewQuestionInput(newInputValue);
                            }}
                            id="lan"
                            options={preview.options}
                            sx={{ width: 180 }}
                            renderInput={(params) => <TextField {...params} label="Question No" />}
                        />
                        <RadiusButton sx={{
                            fontWeight: 500,
                            textTransform: 'uppercase'
                        }} variant='contained' color='primary'
                            size='large' onClick={onView}>
                            View
                        </RadiusButton>
                    </Box>

                    <Stack sx={{ display: 'flex', justifyContent: 'right' }} direction="row" spacing={2}>
                        <RadiusButton sx={{
                            fontWeight: 500,
                            textTransform: 'uppercase'
                        }} variant='contained' color='grey' startIcon={<img src={ARROW_LEFT} alt='' />}
                            size='large' onClick={onGoBack}>
                            Back
                        </RadiusButton>
                        {questionNo > 1 && (
                            <RadiusButton color="primary"
                                variant='contained'
                                endIcon={<img style={{ transform: 'rotate(180deg)' }} src={NEXT} alt='' />}
                                size='large'
                                sx={{
                                    fontWeight: 500,
                                }}
                                onClick={handlePrevious}
                            >PREVIOUS</RadiusButton>
                        )}
                        {questionNo <= maxQuestion && (
                            <RadiusButton color="primary"
                                variant='outlined'
                                startIcon={<img src={EDIT} alt='' />}
                                size='large'
                                sx={{
                                    fontWeight: 500,
                                }}
                                onClick={handleEdit}
                            >EDIT</RadiusButton>
                        )}
                        {questionNo < maxQuestion && (
                            <RadiusButton color="primary"
                                variant='contained'
                                startIcon={<img src={NEXT} alt='' />}
                                size='large'
                                sx={{
                                    fontWeight: 500,
                                }}
                                onClick={handleNext}
                            >NEXT</RadiusButton>
                        )}
                        {questionNo === maxQuestion && (
                            <RadiusButton color="primary"
                                variant='contained'
                                size='large'
                                sx={{
                                    fontWeight: 500,
                                }}
                                onClick={handleFinish}
                            >FINISH</RadiusButton>
                        )}
                    </Stack>
                </Box>

                <Modal
                    hideBackdrop={true}
                    className='backdrop'
                    open={modalData.isVisible}
                    onClose={handleCloseEditModal}
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        bgcolor: 'background.base',
                        boxShadow: 24,
                        borderRadius: 2,
                        p: 3,
                    }}>
                        <Typography variant="h5" component="h5" sx={{
                            mb: 3,
                            color: 'primary.main'
                        }}>Edit Question - {questionNo}</Typography>
                        {configuration?.contentUploadMethod === 'bulk' && <Box sx={{ mb: 1 }}>
                            Question level is
                            {question?.level === 'E' && <Box component='span' sx={{ color: 'primary.main', mx: 0.75 }}>Easy.</Box>}
                            {question?.level === 'M' && <Box component='span' sx={{ color: 'primary.main', mx: 0.75 }}>Medium.</Box>}
                            {question?.level === 'H' && <Box component='span' sx={{ color: 'primary.main', mx: 0.75 }}>Hard.</Box>}
                        </Box>}
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            mb: 3,
                            overflow: 'auto',
                            maxHeight: 320
                        }}>
                            <RadiusButton
                                variant='contained'
                                color='secondary'
                                component='label'
                                htmlFor='fileId'
                                sx={{ mr: 1 }}
                            >Select file</RadiusButton>
                            {modalData?.file?.name}
                            <input
                                style={{ display: 'none' }}
                                type='file'
                                id='fileId'
                                onChange={handleFileDrop}
                                accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                            />
                            {modalData?.file?.name && <img style={{ cursor: 'pointer' }} src={DELETE_ICON} onClick={handleRemoveFile}></img>}
                        </Box>

                        <Box className='actions-container'>
                            <RadiusButton
                                variant='contained'
                                sx={{ ml: 'auto' }}
                                onClick={handleCloseEditModal}
                                disabled={isEditing}
                            >
                                Cancel
                            </RadiusButton>
                            <LoadingButton
                                sx={{
                                    borderRadius: 40,
                                    textTransform: 'capitalize'
                                }}
                                variant='contained'
                                disabled={modalData?.file ? false : true}
                                onClick={handleSubmit}
                                loading={isEditing}
                            >
                                Edit
                            </LoadingButton>
                        </Box>

                    </Box>
                </Modal>

                <ContentUploadErrorsModal currentState={errorModal} setCurrentState={setErrorModal}></ContentUploadErrorsModal>
            </Container>

        </MainContainer>


    );
}
