import { Fragment, useState } from 'react';
import { Box, Typography, Modal, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { LoadingButton } from '@mui/lab'

import { useToaster } from '../../../../providers/ToasterProvider';

import RadiusButton from '../../../../components/RadiusButton';

import DOWNLOAD_BLUE from '../../../../assets/images/download-blue.svg';
import UPLOAD_GREY from '../../../../assets/images/upload-grey.svg';
import UPLOAD_WHITE from '../../../../assets/images/upload-white.svg';
import DELETE_ICON from '../../../../assets/images/action-delete.svg';
import { downloadCandidateTemplate } from '../../../../services/candidateService';
import { updateMaster } from '../../../../services/masterService';
import { updateAnswerKeys } from '../../../../services/questionService';

const columns = [
    { id: 'index', label: 'Row Number', minWidth: 80 },
    { id: 'errors', label: 'Errors', minWidth: 200 },
];

export const UpdateAnswerKeyModal = ({ projectMasterId, isVisible, setIsVisible }) => {

    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadResult, setUploadResult] = useState({
        isProcessed: false,
        errors: []
    });
    const { addNotification } = useToaster();

    const handleFileChange = ({ target }) => {
        const file = target?.files[0];
        if (file && file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setFile(file);
            setUploadResult({
                isProcessed: false,
                errors: []
            })
        }
    }
    const handleFileRemove = () => {
        const element = document.getElementById('candidateFile');
        if (element) {
            element.value = '';
            setFile(null);
            setUploadResult({
                isProcessed: false,
                errors: []
            })
        }
    }
    const handleModalClose = () => {
        setFile(null);
        setIsVisible(false);
        const candidateFileEle = document.getElementById('candidateFile');
        if (candidateFileEle) {
            candidateFileEle.value = '';
        }
    }

    const handleFileUpload = async () => {
        if (!file) return;
        setIsUploading(true);
        try {
            const body = new FormData();
            body.append('projectMasterId', projectMasterId);
            body.append('file', file);
            const response = await updateAnswerKeys(body);
            if (response.code === 'OK') {
                addNotification('Answer keys updated successfully.', 'success');
                handleModalClose();
            } else if (response.code === 'E_BAD_REQUEST') {
                setUploadResult({
                    isProcessed: true,
                    errors: response.data.errors,
                });
            }
        } catch (error) {
            addNotification('Answer keys does not updated.', 'danger');
            console.log('There is an error while uploading bulk users', error);
        } finally {
            setIsUploading(false);
        }
    }
    const renderTableCell = (columnId, rowIndex) => {
        const row = uploadResult.errors[rowIndex];
        switch (columnId) {
            case 'index':
                return row['index'] + 1
            case 'errors':
                return <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                }}>
                    {row['errors'].map((err, index) => {
                        return <Typography variant='subtitle2' key={index}>
                            {err}
                        </Typography>
                    })}
                </Box>
            default:
                return row[columnId];
        }
    }

    return (
        <Modal
            hideBackdrop={true}
            className='backdrop'
            open={isVisible}
            onClose={handleModalClose}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxWidth: 700,
                bgcolor: 'background.base',
                boxShadow: 24,
                borderRadius: 2,
                p: 3,
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 3,
                    gap: 1,
                }}>
                    <Typography variant="h6" component="h6" sx={{
                        color: 'primary.main'
                    }}>
                        Update Answer Key
                    </Typography>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <Box sx={{
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        pb: 1
                    }}>
                        <RadiusButton
                            variant='contained'
                            component='label'
                            htmlFor='candidateFile'
                            color='secondary'
                            sx={{ flexShrink: 0 }}
                        >
                            Select File
                        </RadiusButton>
                        <input
                            style={{ display: 'none' }}
                            type='file'
                            id='candidateFile'
                            onChange={handleFileChange}
                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        />
                        {
                            file && file?.name && <Fragment>
                                <Typography variant='subtitle1' >{file.name}</Typography>
                                <img
                                    style={{ cursor: 'pointer' }}
                                    src={DELETE_ICON}
                                    onClick={handleFileRemove}
                                />
                            </Fragment>
                        }
                    </Box>
                </Box>

                {uploadResult.isProcessed && <>
                    {uploadResult.errors?.length ? <>
                        <Typography sx={{ fontSize: 17, mb: 1 }}>Errors: </Typography>
                        <Paper sx={{
                            width: '100%',
                            overflow: 'hidden',
                            bgcolor: 'background.base',
                            maxHeight: 250,
                            borderRadius: 1,
                            mb: 4
                        }}>
                            <TableContainer sx={{
                                px: 1,
                                pb: 1,
                                maxHeight: 'inherit',
                                maxWidth: 'inherit',
                                border: '1px solid',
                                borderColor: 'primary.main',
                                borderRadius: 1
                            }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    sx={{
                                                        color: 'primary.main',
                                                        p: 1,
                                                    }}
                                                    key={column.id}
                                                    align='center'
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!uploadResult?.errors?.length
                                            ? <TableRow sx={{ mx: 'auto', p: 2, color: 'grey.500' }}>
                                                <TableCell colSpan={2} sx={{ border: 'none', textAlign: 'center' }}>
                                                    No records available
                                                </TableCell>
                                            </TableRow>
                                            : uploadResult.errors.map((row, rowIndex) => {
                                                return (
                                                    <TableRow hover role="checkbox" key={rowIndex}>
                                                        {columns.map((column) => {
                                                            return <TableCell key={column.id} align='center' sx={{
                                                                px: 1.5,
                                                                py: 0.75
                                                            }}>
                                                                {renderTableCell(column.id, rowIndex)}
                                                            </TableCell>
                                                        })}
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </> : null}
                </>}

                <Box className='actions-container'>
                    <RadiusButton
                        variant='contained'
                        color='grey'
                        sx={{ ml: 'auto' }}
                        disabled={isUploading}
                        onClick={handleModalClose}
                    >
                        Cancel
                    </RadiusButton>
                    <LoadingButton
                        sx={{
                            borderRadius: '46px',
                            textTransform: 'none'
                        }}
                        variant='contained'
                        onClick={handleFileUpload}
                        disabled={isUploading || !file}
                        loading={isUploading}
                        loadingPosition="start"
                        startIcon={(isUploading || !file) ? <img src={UPLOAD_GREY} alt='' /> : <img src={UPLOAD_WHITE} alt='' />}
                    >
                        Upload
                    </LoadingButton>
                </Box>

            </Box>
        </Modal>
    );
}
