import { Fragment, useState } from 'react';
import { Box, Typography, Modal, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { LoadingButton } from '@mui/lab'

import { useToaster } from '../../../../providers/ToasterProvider';

import RadiusButton from '../../../../components/RadiusButton';

import DOWNLOAD_BLUE from '../../../../assets/images/download-blue.svg';
import UPLOAD_GREY from '../../../../assets/images/upload-grey.svg';
import UPLOAD_WHITE from '../../../../assets/images/upload-white.svg';
import DELETE_ICON from '../../../../assets/images/action-delete.svg';
import { downloadCandidateTemplate } from '../../../../services/candidateService';
import { updateMaster, uploadCandidate } from '../../../../services/masterService';
import mimes from '../../../../constant/mimes';

const columns = [
    { id: 'index', label: 'Row Number', minWidth: 140 },
    { id: 'rollNo', label: 'Roll No', minWidth: 140 },
    { id: 'userName', label: 'User Name', minWidth: 140 },
    { id: 'errors', label: 'Errors', minWidth: 190 },
];

export const CandidateUploadModal = ({ modalData, setModalData, dataUpdated, setDataUpdated }) => {

    const [candidateFile, setCandidateFile] = useState(null);
    const [photoFile, setPhotoFile] = useState(null);
    const [signFile, setSignFile] = useState(null);
    const [uploadResult, setUploadResult] = useState({
        isProcessed: false,
        createdRecordsCount: 0,
        errors: []
    });
    const { addNotification } = useToaster();
    const [isUploading, setIsUploading] = useState(false);

    const handleFileChange = ({ target }) => {
        const file = target?.files[0];
        if (file && file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setCandidateFile(file);
            setUploadResult({
                isProcessed: false,
                errors: [],
                createdCount: 0,
            });
        }
    }
    const handleFileRemove = () => {
        const element = document.getElementById('candidateFile');
        if (element) {
            element.value = '';
            setCandidateFile(null);
        }
    }
    const handlePhotoFileChange = ({ target }) => {
        const file = target?.files[0];
        if (file && mimes.ZIP.includes(file?.type)) {
            setPhotoFile(file);
            setUploadResult({
                isProcessed: false,
                errors: [],
                createdCount: 0,
            });
        }
    }
    const handlePhotoFileRemove = () => {
        const element = document.getElementById('photoFile');
        if (element) {
            element.value = '';
            setPhotoFile(null);
        }
    }
    const handleSignFileChange = ({ target }) => {
        const file = target?.files[0];
        if (file && mimes.ZIP.includes(file?.type)) {
            setSignFile(file);
            setUploadResult({
                isProcessed: false,
                errors: [],
                createdCount: 0,
            });
        }
    }
    const handleSignFileRemove = () => {
        const element = document.getElementById('signFile');
        if (element) {
            element.value = '';
            setSignFile(null);
        }
    }
    const handleModalClose = () => {
        setModalData({
            ...modalData,
            isVisible: false,
        });
        setCandidateFile(null);
        setPhotoFile(null);
        setSignFile(null);
        setUploadResult({
            isProcessed: false,
            createdCount: 0,
            errors: [],
        })
        const candidateFileEle = document.getElementById('candidateFile');
        if (candidateFileEle) {
            candidateFileEle.value = '';
        }
        const photoFileEle = document.getElementById('photoFile');
        if (photoFileEle) {
            photoFileEle.value = '';
        }
        const signFileEle = document.getElementById('signFile');
        if (signFileEle) {
            signFileEle.value = '';
        }
    }
    const handleDownloadTemplate = async () => {
        try {
            const res = await downloadCandidateTemplate();
            const url = window.URL.createObjectURL(res.data);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `candidate-template.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            addNotification('File downloaded successfully.', 'success');
        } catch (error) {
            console.log('error', error);
            addNotification('Unexpected error occurred', 'danger');
        }
    }
    const handleFileUpload = async () => {
        if (!candidateFile || !photoFile || !signFile || !modalData?.projectMasterId) return;
        setIsUploading(true);
        try {
            const body = new FormData();
            body.append('candidateFile', candidateFile);
            body.append('photoFile', photoFile);
            body.append('signFile', signFile);
            const response = await uploadCandidate(modalData.projectMasterId, body);
            if (response.code === 'OK') {
                if (response.data?.errors?.length) {
                    setUploadResult({
                        isProcessed: true,
                        createdCount: response.data.createdCount,
                        errors: response.data.errors,
                    });
                } else {
                    addNotification('Candidates added successfully.', 'success');
                    setDataUpdated(!dataUpdated);
                    handleModalClose();
                }
            }
        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'E_EXAM_ALREADY_ENABLED') {
                addNotification('Exam enabled for selected master.', 'danger');
            } else {
                addNotification('Unexpected error occured.', 'danger');
            }
            console.log('There is an error while uploading bulk users', error);
        } finally {
            setIsUploading(false);
        }
    }
    const renderTableCell = (columnId, rowIndex) => {
        const row = uploadResult.errors[rowIndex];
        switch (columnId) {
            case 'index':
                return row['index']
            case 'rollNo':
                return row['rollNo']
            case 'userName':
                return row['userName']
            case 'errors':
                return <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                }}>
                    {row['errors'].map((err, index) => {
                        return <Typography key={index}>
                            {err}
                        </Typography>
                    })}

                </Box>
            default:
                return row[columnId];
        }
    }

    return (
        <Fragment>
            <Modal
                open={modalData?.isVisible}
                onClose={handleModalClose}
                hideBackdrop={true}
                className='backdrop'
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: 900,
                    bgcolor: 'background.base',
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 3,
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 3,
                        gap: 1,
                    }}>
                        <Typography variant="h6" component="h6" sx={{
                            color: 'primary.main'
                        }}>
                            Candidates Upload
                        </Typography>
                        <RadiusButton
                            variant='outlined'
                            startIcon={<img src={DOWNLOAD_BLUE} />}
                            onClick={handleDownloadTemplate}
                        >
                            Download Template
                        </RadiusButton>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <Box sx={{
                            overflow: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            pb: 1
                        }}>
                            <RadiusButton
                                variant='contained'
                                component='label'
                                htmlFor='candidateFile'
                                color='secondary'
                                sx={{ flexShrink: 0 }}
                            >
                                Select Candidates File
                            </RadiusButton>
                            <input
                                style={{ display: 'none' }}
                                type='file'
                                id='candidateFile'
                                onChange={handleFileChange}
                                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            />
                            {
                                candidateFile && candidateFile?.name && <Fragment>
                                    <Typography variant='subtitle1' >{candidateFile.name}</Typography>
                                    <img
                                        style={{ cursor: 'pointer' }}
                                        src={DELETE_ICON}
                                        onClick={handleFileRemove}
                                    />
                                </Fragment>
                            }
                        </Box>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <Box sx={{
                            overflow: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            pb: 1
                        }}>
                            <RadiusButton
                                variant='contained'
                                component='label'
                                htmlFor='photoFile'
                                color='secondary'
                                sx={{ flexShrink: 0 }}
                            >Select Photo File</RadiusButton>
                            <input
                                style={{ display: 'none' }}
                                type='file'
                                id='photoFile'
                                onChange={handlePhotoFileChange}
                                accept={mimes.ZIP}
                            />
                            {
                                photoFile && photoFile?.name && <Fragment>
                                    <Typography variant='subtitle1' >{photoFile.name}</Typography>
                                    <img
                                        style={{ cursor: 'pointer' }}
                                        src={DELETE_ICON}
                                        onClick={handlePhotoFileRemove}
                                    />
                                </Fragment>
                            }
                        </Box>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <Box sx={{
                            overflow: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            pb: 1
                        }}>
                            <RadiusButton
                                variant='contained'
                                component='label'
                                htmlFor='signFile'
                                color='secondary'
                                sx={{ flexShrink: 0 }}
                            >Select Signature File</RadiusButton>
                            <input
                                style={{ display: 'none' }}
                                type='file'
                                id='signFile'
                                onChange={handleSignFileChange}
                                accept={mimes.ZIP}
                            />
                            {
                                signFile && signFile?.name && <Fragment>
                                    <Typography variant='subtitle1' >{signFile.name}</Typography>
                                    <img
                                        style={{ cursor: 'pointer' }}
                                        src={DELETE_ICON}
                                        onClick={handleSignFileRemove}
                                    />
                                </Fragment>
                            }
                        </Box>
                    </Box>

                    {uploadResult.isProcessed && <>
                        <Typography sx={{ fontSize: 17, mb: 2 }}>Successfully created records count: {uploadResult.createdCount}</Typography>
                        {uploadResult.errors?.length ? <>
                            <Typography sx={{ fontSize: 17, mb: 1 }}>Errors: </Typography>
                            <Paper sx={{
                                width: '100%',
                                overflow: 'hidden',
                                bgcolor: 'background.base',
                                maxHeight: 250,
                                borderRadius: 1,
                                mb: 4
                            }}>
                                <TableContainer sx={{
                                    px: 1,
                                    pb: 1,
                                    maxHeight: 'inherit',
                                    maxWidth: 'inherit',
                                    border: '1px solid',
                                    borderColor: 'primary.main',
                                    borderRadius: 1
                                }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        sx={{
                                                            color: 'primary.main',
                                                            p: 1,
                                                        }}
                                                        key={column.id}
                                                        align='center'
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!uploadResult?.errors?.length
                                                ? <TableRow sx={{ mx: 'auto', p: 2, color: 'grey.500' }}>
                                                    <TableCell colSpan={7} sx={{ border: 'none', textAlign: 'center' }}>
                                                        No records available
                                                    </TableCell>
                                                </TableRow>
                                                : uploadResult.errors.map((row, rowIndex) => {
                                                    return (
                                                        <TableRow hover role="checkbox" key={rowIndex}>
                                                            {columns.map((column) => {
                                                                return <TableCell key={column.id} align='center' sx={{
                                                                    px: 1.5,
                                                                    py: 0.75
                                                                }}>
                                                                    {renderTableCell(column.id, rowIndex)}
                                                                </TableCell>
                                                            })}
                                                        </TableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </> : null}
                    </>}

                    <Box className='actions-container'>
                        <RadiusButton
                            variant='contained'
                            color='grey'
                            sx={{ ml: 'auto' }}
                            onClick={handleModalClose}
                            disabled={isUploading}
                        >
                            Cancel
                        </RadiusButton>
                        <LoadingButton
                            sx={{
                                borderRadius: '46px',
                                textTransform: 'none'
                            }}
                            variant='contained'
                            onClick={handleFileUpload}
                            disabled={isUploading || !candidateFile || !photoFile || !signFile}
                            loading={isUploading}
                            loadingPosition="start"
                            startIcon={(isUploading || !candidateFile || !photoFile || !signFile) ? <img src={UPLOAD_GREY} alt='' /> : <img src={UPLOAD_WHITE} alt='' />}
                        >
                            Upload
                        </LoadingButton>
                    </Box>

                </Box>
            </Modal>
        </Fragment>
    );
}
