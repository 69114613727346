import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Typography, Box, FormControl, Paper, TextField, Select, MenuItem, InputLabel } from '@mui/material';
import dayjs from '../../../../config/dayjs';

import { useToaster } from "../../../../providers/ToasterProvider";
import { enableExam } from '../../../../services/masterService';
import { getDataForEnableExam } from '../../../../services/masterService';
import { MainContainer, Container } from '../../../../components';
import { Menu } from '../Menu/Menu';

import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import RadiusButton from '../../../../components/RadiusButton';
import { LoadingButton } from '@mui/lab';

export const EnableExam = () => {

    const params = useParams();
    const projectName = params?.projectName;
    const location = useLocation();
    const navigate = useNavigate();
    const { addNotification } = useToaster();
    const [configuration, setConfiguration] = useState({
        driveName: '',
        courseName: '',
        batchName: '',
        examDate: '',
    });
    const [projectMasterId, setProjectMasterId] = useState(null);
    const [assignedSets, setAssignedSets] = useState([]);
    const [availabelSets, setAvailableSets] = useState([]);
    const [selectedSet, setSelectedSet] = useState(0);
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [isEnabling, setIsEnabling] = useState(false);

    useEffect(() => {

        const projectMasterId = location?.state?.projectMasterId;
        const driveName = location?.state?.driveName;
        const courseName = location?.state?.courseName;
        const batchName = location?.state?.batchName;
        const examDate = location?.state?.examDate;
        if (projectMasterId && driveName && courseName && batchName && examDate) {
            setConfiguration({
                driveName,
                courseName,
                batchName,
                examDate
            });
            setProjectMasterId(projectMasterId);
            const fetchData = async () => {
                try {
                    const res = await getDataForEnableExam(projectMasterId);
                    if (res.code === 'OK') {
                        setAssignedSets(res.data.assignedSets);
                        setStartTime(dayjs.utc(res.data.startTime));
                        setEndTime(dayjs.utc(res.data.endTime));
                        setAvailableSets(Array.from({ length: res.data.numberOfSets }, (_, i) => i + 1))
                    }
                } catch (error) {
                    console.log('There is an error while getting data for enable exam', error);
                }
            }
            fetchData();
        } else {
            if (projectName) {
                navigate(`/${projectName}/content-dashboard`)
            }
        }
    }, [location]);

    const handleSetChange = (e, v) => {
        const setNumber = v.props.value;
        setSelectedSet(setNumber)
    }

    const handleEnableExam = async () => {
        if (projectMasterId && selectedSet) {
            setIsEnabling(true);
            try {
                const res = await enableExam(projectMasterId, { set: selectedSet })
                if (res.code === 'OK') {
                    // setAssignedSets([...assignedSets, selectedSet]);
                    // setSelectedBatch(null);
                    // setStartTime(null);
                    // setEndTime(null);
                    // setSelectedSet(0)
                    addNotification('Exam enabled successfully.', 'success');
                    if (projectName) {
                        navigate(`/${projectName}/content-dashboard`);
                    }
                }
            } catch (error) {
                const code = error?.response?.data?.code;
                if (code === 'E_EXAM_ALREADY_ENABLED') {
                    addNotification('Exam already enabled.', 'danger');
                } else if (code === 'CANDIDATES_NOT_FOUND') {
                    addNotification('Candidates not found.', 'danger');
                }
                console.log('There is an error while enabling exam', error);
            } finally {
                setIsEnabling(false);
            }
        }
    }

    return (
        <MainContainer>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{
                        fontWeight: 400,
                        textTransform: 'capitalize'
                    }}>{projectName}</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>Enable Exam Schedule</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>

            <Container>
                <div className="search-header-wrapper" sx={{ mb: 1.5 }}>
                    <h1 className='title' style={{ margin: '0px' }}>Enable Exam Schedule</h1>
                </div>

                <Paper sx={{
                    width: '100%',
                    overflow: 'hidden',
                    bgcolor: 'background.base',
                    maxWidth: 'calc(100vw - 140px)',
                    borderRadius: 3,
                    px: 3,
                    py: 5,
                    mb: 4
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}>
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center'
                        }}>
                            <Typography variant='h6' sx={{ minWidth: 200 }}>Drive</Typography>
                            <Typography variant='h6' sx={{ color: 'primary.main', textTransform: 'capitalize' }}>{configuration?.driveName}</Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center'
                        }}>
                            <Typography variant='h6' sx={{ minWidth: 200 }}>Course</Typography>
                            <Typography variant='h6' sx={{ color: 'primary.main', textTransform: 'capitalize' }}>{configuration?.courseName}</Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center'
                        }}>
                            <Typography variant='h6' sx={{ minWidth: 200 }}>Batch</Typography>
                            <Typography variant='h6' sx={{ color: 'primary.main', textTransform: 'capitalize' }}>{configuration?.batchName}</Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center'
                        }}>
                            <Typography variant='h6' sx={{ minWidth: 200 }}>Exam Date</Typography>
                            <Typography variant='h6' sx={{ color: 'primary.main', textTransform: 'capitalize' }}>{configuration?.examDate}</Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center'
                        }}>
                            <Typography variant='h6' sx={{ minWidth: 200 }}>Start Time</Typography>
                            <Typography variant='h6' sx={{ color: 'primary.main' }}>{startTime?.format('hh:mm A')}</Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center'
                        }}>
                            <Typography variant='h6' sx={{ minWidth: 200 }}>End Time</Typography>
                            <Typography variant='h6' sx={{ color: 'primary.main' }}>{endTime?.format('hh:mm A')}</Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            mt: 3
                        }}>
                            <Box sx={{ minWidth: 200 }}>
                                <FormControl sx={{ width: 180, bgcolor: 'background.base' }}>
                                    <InputLabel>Set</InputLabel>
                                    <Select
                                        onChange={handleSetChange}
                                        value={selectedSet}
                                        label="Set"
                                    >
                                        <MenuItem value={0}>None</MenuItem>
                                        {availabelSets?.map((setNumber, index) => {
                                            return <MenuItem
                                                key={index}
                                                value={setNumber}
                                                disabled={assignedSets.includes(setNumber) ? true : false}
                                            >Set {setNumber}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <LoadingButton
                                sx={{ borderRadius: 40, textTransform: 'capitalize' }}
                                loading={isEnabling}
                                variant='contained'
                                size='large'
                                disabled={(!startTime || !endTime || !selectedSet) ? true : false}
                                onClick={handleEnableExam}
                            >Enable</LoadingButton>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </MainContainer >
    );
}