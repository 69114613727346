import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Typography, Table, TableContainer, Paper, FormControlLabel, TableHead, TableRow, TableCell, TableBody, Pagination, Switch, Modal } from '@mui/material';
import { Container, MainContainer } from '../../../../components';
import { Menu } from '../Menu/Menu';

import { getListByPageAndType, updateById, deleteById } from '../../../../services/usersService';
import RadiusButton from '../../../../components/RadiusButton'
import { useToaster } from '../../../../providers/ToasterProvider';
import { BulkUserUploadModal } from '../BulkUserUploadModal/BulkUserUploadModal';
import dayjs from '../../../../config/dayjs';

import SEARCH_ICON from '../../../../assets/images/search-icon.svg';
import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
// import UPLOAD from '../../../../assets/images/upload.svg';
import UPLOAD_WHITE from '../../../../assets/images/upload-white.svg';
import VIEW_CONTENT from '../../../../assets/images/content.svg';
import ADD_IT_SUPPORT from '../../../../assets/images/it-support-white.svg';
import VIEW_USER from '../../../../assets/images/add-user.svg';
import VIEW_BIOMETRIC from '../../../../assets/images/biometric.svg';
import PENCIL from '../../../../assets/images/pencil.svg';
import ACTION_DELETE from '../../../../assets/images/action-delete.svg';

import './ItSupport.scss';
import { ProjectContext } from '../../../../providers/projectProvider';

const limit = process.env.REACT_APP_PAGE_LIMIT;
let timer = null;

export const ItSupport = () => {
    const columns = [
        { id: 'name', label: 'Name', minWidth: 150 },
        { id: 'email', label: 'Email', minWidth: 220 },
        { id: 'drive', label: 'Drive Name', minWidth: 120 },
        { id: 'course', label: 'Course Name', minWidth: 120 },
        { id: 'batch', label: 'Batch Name', minWidth: 120 },
        { id: 'examHall', label: 'Exam Hall Name', minWidth: 140 },
        { id: 'serverId', label: 'Server ID', minWidth: 120 },
        { id: 'examDate', label: 'Exam Date', minWidth: 120 },
        { id: 'actions', label: 'Access', minWidth: 200 },
        { id: 'status', label: '', minWidth: 140 },
    ];

    const params = useParams();
    const projectName = params?.projectName;
    const navigate = useNavigate();
    const { projectDetails } = useContext(ProjectContext);
    const [maxPages, setMaxPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState(null);
    const [deleteModalToggler, setDeleteModalToggler] = useState(false);
    const { addNotification } = useToaster();
    const [bulkModalVisible, setBulkModalVisible] = useState(false);
    const [usersUpdated, setUsersUpdated] = useState(false);
    const [search, setSearch] = useState('');
    const [newSearch, setNewSearch] = useState(false);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const res = await getListByPageAndType(6, search, currentPage, limit);
                if (res.code === 'OK') {
                    const maxP = Math.ceil(res.data.count / limit);
                    setMaxPages(maxP);
                    setUsers(
                        res.data.rows.map((user) => {
                            user.name = `${user.firstName} ${user.lastName}`;
                            return user;
                        })
                    );
                }
            } catch (error) {
                console.log('There is an error while fetching it support users', error);
            }
        }
        fetchUsers();
    }, [currentPage, newSearch, usersUpdated]);

    const goToUsers = () => {
        if (projectName) {
            navigate(`/${projectName}/users`);
        }
    }
    const goToContentUsers = () => {
        if (projectName) {
            navigate(`/${projectName}/content`);
        }
    }
    const goToBiometric = () => {
        if (projectName) {
            navigate(`/${projectName}/biometric`);
        }
    }

    const onAddItSupportUser = () => {
        if (projectName) {
            navigate(`/${projectName}/it-support/it-support-details`);
        }
    }

    const handleActiveChange = async (userIndex, value) => {
        const user = users[userIndex];
        if (user?.id) {
            try {
                const res = await updateById(user.id, { isActive: value })
                if (res.code === 'OK') {
                    setUsers(
                        users.map((u) => {
                            if (u.id === user.id) {
                                u.isActive = value;
                            }
                            return u;
                        })
                    )
                }
            } catch (error) {
                console.log('There is an error while updating user', error);
            }
        }
    }

    const handleDeleteUser = (userIndex) => {
        const user = users[userIndex];
        if (user?.id) {
            setUserId(user.id);
            setDeleteModalToggler(true);
        }
    }

    const onConfirmDelete = async () => {
        try {
            const res = await deleteById(userId);
            if (res.code === 'OK') {
                setDeleteModalToggler(false);
                setUsers(users.filter((u) => u.id !== userId));
                setUserId(null);
                addNotification('User has been deleted successfully.', 'success');
            }
        } catch (error) {
            console.log('There is an error while deleting biometric user', error);
        }
    }
    const handleUploadBulk = () => {
        setBulkModalVisible(true);
    }
    const onSearch = (e) => {
        setSearch(e.target.value);
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            setCurrentPage(1);
            setNewSearch(!newSearch);
        }, 400);
    }
    const renderTableCell = (columnId, rowIndex) => {
        const row = users[rowIndex];
        switch (columnId) {
            case 'drive':
                return row?.['drive.driveName'];
            case 'examHall':
                return row?.['examHall.hallName'];
            case 'course':
                return row?.['course.courseName'];
            case 'batch':
                return row?.['batch.batchName'];
            case 'serverId':
                return row?.['server.serverId'];
            case 'examDate':
                return row?.examDate ? dayjs(row.examDate).format('DD-MM-YYYY') : null;
            case 'actions':
                return <>
                    <Box component='span' sx={{
                        mr: 1,
                        cursor: 'pointer'
                    }}>
                        <Link to={'it-support-details'} state={{ userId: row.id }}>
                            <img src={PENCIL} alt='' />
                            <Typography variant='body1' sx={{
                                display: 'inline-block',
                                verticalAlign: 'super',
                                ml: 1,
                                color: 'success.main'
                            }}>Edit</Typography>
                        </Link>
                    </Box>
                    <Box component='span' sx={{
                        mx: 1,
                        cursor: 'pointer'
                    }} onClick={() => handleDeleteUser(rowIndex)}>
                        <img src={ACTION_DELETE} alt='' />
                        <Typography variant='body1' sx={{
                            display: 'inline-block',
                            verticalAlign: 'super',
                            ml: 1,
                            color: 'error.main'
                        }}>Delete</Typography>
                    </Box>
                </>;
            case 'status':
                return <FormControlLabel
                    value="end"
                    control={<Switch checked={row?.['isActive']} onChange={(e, value) => handleActiveChange(rowIndex, value)} />}
                    label="Active"
                    labelPlacement="end"
                />
            default:
                return row?.[columnId];
        }
    }

    return (
        <MainContainer sx={{ bgcolor: 'red' }}>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{
                        fontWeight: 400,
                        textTransform: 'capitalize'
                    }}>{projectDetails?.projectName}</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>IT Support</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>
            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <h1 className='title'>IT Support List</h1>

                <div className="search-header-wrapper">
                    <div className='search-wrapper'>
                        <input type='text' className='search-box' value={search} onChange={onSearch} placeholder='Search...' />
                        <img className='search-icon' src={SEARCH_ICON} alt='' />
                    </div>

                    <RadiusButton sx={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }} color="secondary" type="submit" variant='contained' size='large'
                        startIcon={<img src={VIEW_USER} alt='' />} onClick={goToUsers}>
                        View User
                    </RadiusButton>

                    <RadiusButton sx={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }} color="secondary" type="submit" variant='contained' size='large'
                        startIcon={<img src={VIEW_CONTENT} alt='' />} onClick={goToContentUsers}>
                        View Content
                    </RadiusButton>

                    <RadiusButton sx={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }} color="secondary" type="submit" variant='contained' size='large'
                        startIcon={<img src={VIEW_BIOMETRIC} alt='' />} onClick={goToBiometric}>
                        View Biometric
                    </RadiusButton>
                </div>

                <Paper sx={{
                    width: '100%',
                    overflow: 'hidden',
                    bgcolor: 'background.base',
                    maxHeight: 'calc(100vh - 305px)',
                    maxWidth: 'calc(100vw - 140px)',
                    borderRadius: 1,
                    mb: 4
                }}>
                    <TableContainer sx={{
                        px: 1,
                        pb: 1,
                        maxHeight: 'inherit',
                        maxWidth: 'inherit',
                        border: '1px solid',
                        borderColor: 'primary.main',
                        borderRadius: 1
                    }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            sx={{
                                                color: 'primary.main',
                                                px: 1.5
                                            }}
                                            key={column.id}
                                            align='center'
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!users?.length
                                    ? <TableRow sx={{ mx: 'auto', p: 2, color: 'grey.500' }}>
                                        <TableCell colSpan={10} sx={{ border: 'none', textAlign: 'center' }}>
                                            No records available
                                        </TableCell>
                                    </TableRow>
                                    : users.map((row, rowIndex) => {
                                        return (
                                            <TableRow hover role="checkbox" key={rowIndex}>
                                                {columns.map((column) => {
                                                    return <TableCell key={column.id} align='center' sx={{
                                                        px: 1.5,
                                                        py: 0.75
                                                    }}>
                                                        {renderTableCell(column.id, rowIndex)}
                                                    </TableCell>
                                                })}
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 2.5,
                    mt: 'auto'
                }}>

                    <RadiusButton sx={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }} color='primary'
                        onClick={onAddItSupportUser}
                        variant='contained'
                        size='large'
                        startIcon={<img src={ADD_IT_SUPPORT} alt='' />}
                    >
                        Add It Support User
                    </RadiusButton>
                    <RadiusButton sx={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                    }} color='primary'
                        onClick={handleUploadBulk}
                        variant='contained'
                        size='large'
                        startIcon={<img src={UPLOAD_WHITE} alt='' />}
                    >
                        Bulk upload
                    </RadiusButton>


                    <Pagination sx={{
                        ml: 'auto'
                    }} count={maxPages} page={currentPage} onChange={handlePageChange} shape="rounded" />
                </Box>

                <Modal open={deleteModalToggler} hideBackdrop={true} className='backdrop' onClose={() => setDeleteModalToggler(false)}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.base',
                        boxShadow: 24,
                        borderRadius: 2,
                        p: 9,
                    }}>
                        <Typography variant='h6' sx={{
                            fontWeight: 400,
                            mb: 8
                        }}>
                            Do you want to delete this user?
                        </Typography>

                        <div className='actions-container' style={{ justifyContent: 'center', gap: '40px' }}>
                            <RadiusButton variant='outlined' onClick={() => setDeleteModalToggler(false)}>
                                No
                            </RadiusButton>
                            <RadiusButton variant='contained' onClick={() => onConfirmDelete()}>
                                Yes
                            </RadiusButton>
                        </div>
                    </Box>
                </Modal>
                <BulkUserUploadModal isVisible={bulkModalVisible} setIsVisible={setBulkModalVisible} userType={6} dataUpdated={usersUpdated} setDataUpdated={setUsersUpdated} />

            </Container>
        </MainContainer >
    );
}
