import { Box, Modal, Typography } from "@mui/material";
import RadiusButton from '../../../../components/RadiusButton';
import { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../../../providers/projectProvider";
import moduleTypes from "../../../../constant/moduleTypes";
import { sendWelcomeMail } from "../../../../services/usersService";
import { useToaster } from '../../../../providers/ToasterProvider';


export const UserCreationMail = ({ userDetails, openState, isUpdated, setMailModal }) => {

    const { projectDetails } = useContext(ProjectContext)
    const [displayModules, setDisplayModules] = useState('');
    const { addNotification } = useToaster();

    useEffect(() => {
        if (userDetails?.modules) {
            const accessedModules = userDetails.modules.map((m) => +m);
            const modulesToDisplay = moduleTypes.filter((module) => accessedModules.includes(module.code)).map((m) => m.displayKey);
            setDisplayModules(modulesToDisplay.join(','))
        }
    }, [userDetails]);

    const handleClose = () => {
        setMailModal({
            isOpen: false,
            cancelPressed: true
        });
    };

    const handleMailSending = async () => {
        if (userDetails?.id) {
            try {
                const body = {
                    userId: userDetails.id,
                    email: userDetails.email,
                    password: userDetails.password,
                    projectName: projectDetails?.projectName,
                    loginUrl: userDetails.loginUrl,
                    // loginUrl: `${process.env.REACT_APP_API_URL.replace('/api', '')}/${projectDetails?.projectShortName}/login`,
                    isUpdated: isUpdated,
                }
                if (userDetails?.userType === 3) {
                    body.assignedModules = displayModules;
                }

                const res = await sendWelcomeMail(body);
                if (res.code === 'OK') {
                    addNotification('Mail sent successfully.');
                    handleClose();
                }
            } catch (error) {
                console.log('There is an error while sending mail', error);
            }
        }
    }

    return (
        <Modal
            hideBackdrop={true}
            className='backdrop'
            open={openState}
            onClose={handleClose}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 800,
                bgcolor: 'background.base',
                boxShadow: 24,
                borderRadius: 2,
                p: 3,
            }}>
                <Typography variant="h5" component="h5" sx={{
                    mb: 1.5,
                    color: 'primary.main'
                }}>
                    User created successfully send mail
                </Typography>

                <Typography variant="h6" sx={{
                    fontSize: 18,
                    mb: 2
                }}>
                    Hi {userDetails?.firstName},
                </Typography>

                <Typography variant="subtitle1" sx={{
                    mb: 2
                }}>
                    We are excited to welcome you to our project portal! Here are the details you will need to log in and start operating the "Assigned Module List" activities and update records:
                </Typography>

                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: 'max-content 1fr',
                    columnGap: 2,
                    rowGap: 1,
                    mb: 3
                }}>
                    <Typography variant="h6" sx={{ fontSize: 18 }}>User name</Typography>
                    <Typography variant="h6" sx={{ fontSize: 18 }}>{userDetails?.email}</Typography>
                    <Typography variant="h6" sx={{ fontSize: 18 }}>password</Typography>
                    <Typography variant="h6" sx={{ fontSize: 18 }}>{userDetails?.password}</Typography>
                    <Typography variant="h6" sx={{ fontSize: 18 }}>Project name</Typography>
                    <Typography variant="h6" sx={{ fontSize: 18 }}>{projectDetails?.projectName}</Typography>
                    <Typography variant="h6" sx={{ fontSize: 18 }}>Login url</Typography>
                    <Typography variant="h6" sx={{ fontSize: 18, wordBreak: 'break-all' }}>{userDetails?.loginUrl}</Typography>
                    {/* <Typography variant="h6" sx={{ fontSize: 18 }}>{`${process.env.REACT_APP_API_URL.replace('/api', '')}/${projectDetails?.projectShortName}/login`}</Typography> */}
                    {
                        userDetails?.userType === 3 && userDetails?.modules && (
                            <>
                                <Typography variant="h6" sx={{ fontSize: 18 }} >Allocated Modules</Typography>
                                <Typography variant="h6" sx={{ fontSize: 18 }}>{displayModules}</Typography>
                            </>
                        )
                    }
                </Box>

                <Box className='actions-container'>
                    <RadiusButton variant='outlined' sx={{ ml: 'auto' }} onClick={handleClose}>
                        Don't Send
                    </RadiusButton>
                    <RadiusButton variant='contained' onClick={handleMailSending}>
                        Send
                    </RadiusButton>
                </Box>

            </Box>
        </Modal>
    );
}
