import { useContext, useRef, useState } from "react";
import { Box, List, ListItemButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../../../providers/auth";

import useClickoutHook from "../../../../hooks/useClickoutHook";
import { logout } from "../../../../services/authService";

import USER_PROFILE from '../../../../assets/images/user-profile.svg'
import USER_MORE from '../../../../assets/images/user-more.svg';
import BRAND_IMAGE from '../../../../assets/images/logo.png';

import './SuperAdminMenu.scss';

export const SuperAdminMenu = ({ children }) => {

    const { user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const [userMenuToggler, setUserMenuToggler] = useState(false);
    const handleLogout = async () => {
        let redirectPath = '/login';
        if (user?.userType === 1) {
            redirectPath = '/login';
            console.log(redirectPath);
        }
        await logout();
        setUser(null);
        navigate(redirectPath);
    }

    const menuWrapper = useRef(null);
    useClickoutHook(menuWrapper, () => setUserMenuToggler(false));

    return (
        <Box sx={{ display: 'flex', py: 1.5, px: 3, alignItems: 'center', justifyContent: 'flex-start', height: 80, top: 0, gap: 1.5 }}>
            <Box sx={{
                p: 1,
                height: 80,
                flexShrink: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'background.default',
                borderColor: 'grey.300'
            }}>
                <img className='brand-image' src={BRAND_IMAGE} alt='brand' />
            </Box>

            {children}

            <div className='menu-wrapper' style={{ gap: '12px'}}>
                {/* <div className='notification-wrapper'>
                    <img className='icon' src={NOTIFICATION_BELL} alt='bell' />
                </div> */}

                {user && <Typography sx={{alignSelf: 'center', fontSize: 16, fontWeight: 500, textTransform: 'capitalize' }}>
                    {user?.firstName + ' ' + user?.lastName}
                </Typography>}

                {
                    user && <div className='user-wrapper'>
                        <div className='user-toggler-wrapper' onClick={() => setUserMenuToggler(!userMenuToggler)}>
                            <div className='user-toggler'>
                                <img className='icon' src={USER_PROFILE} alt='profile' />
                                <div className='more-toggler'>
                                    <img className='icon' src={USER_MORE} alt='more' />
                                </div>
                            </div>
                        </div>

                        {
                            userMenuToggler && <div className='list-wrapper' ref={menuWrapper}>
                                <List component="nav" aria-label="secondary mailbox folder" sx={{ bgcolor: 'background.base' }}>
                                    <ListItemButton onClick={handleLogout}>
                                        Logout
                                    </ListItemButton>
                                </List>
                            </div>
                        }

                    </div>
                }

            </div>
        </Box>
    );
}