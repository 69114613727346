import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Typography, Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Pagination, Modal } from '@mui/material';

import { useToaster } from '../../../../providers/ToasterProvider';
import { AuthContext } from '../../../../providers/auth';
import { listForContentDashboard } from '../../../../services/masterService';
import { lockExam } from '../../../../services/configurationService';

import { Container, MainContainer } from '../../../../components';
import { Menu } from '../Menu/Menu';
import RadiusButton from '../../../../components/RadiusButton'

import SEARCH_ICON from '../../../../assets/images/search-icon.svg';
import ARROW from '../../../../assets/images/breadcrumb-arrow.svg';
import ARROW_OPEN from '../../../../assets/images/breadcrumb-arrow-open.svg';
import { ProjectContext } from '../../../../providers/projectProvider';

const limit = process.env.REACT_APP_PAGE_LIMIT;

export const ContentDashboard = () => {

    const params = useParams();
    const projectName = params?.projectName;
    const navigate = useNavigate();
    const { addNotification } = useToaster();
    const { user } = useContext(AuthContext);
    const { projectDetails } = useContext(ProjectContext);

    const [maxPages, setMaxPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [rows, setRows] = useState([]);
    const [lockExamModal, setLockExamModal] = useState({
        isVisible: false,
        configurationId: null
    })

    const columns = [
        { id: 'driveName', label: 'Drive Name', minWidth: 120 },
        { id: 'courseName', label: 'Course Name', minWidth: 120 },
        { id: 'contentConfigured', label: 'Content Configured', minWidth: 100 },
        { id: 'contentUploaded', label: 'Content Uploaded', minWidth: 100 },
        { id: 'languages', label: 'Bilingual Data Uploaded', minWidth: 220 },
        { id: 'instructionUploaded', label: 'Instruction Uploaded', minWidth: 100 },
        { id: 'feedbackUploaded', label: 'Feedback Uploaded', minWidth: 100 },
        { id: 'isLocked', label: 'Data Locked', minWidth: 100 },
        { id: 'examEnabled', label: 'Exam Enabled', minWidth: 100 },
    ];

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await listForContentDashboard(currentPage, limit);
                if (res.code === 'OK') {
                    const max = Math.ceil(res.data.count / limit);
                    setMaxPages(max);
                    setRows(
                        res.data.rows.map((pm) => {
                            if (pm.configurationId) {
                                let languages = []
                                for (let language of pm.languages) {
                                    languages.push({
                                        language: language,
                                        isUploaded: true,
                                    });
                                }
                                for (let setNumber of Object.keys(pm.uploadedSets)) {
                                    for (let language of languages) {
                                        if (!pm.uploadedSets[setNumber].includes(language.language)) {
                                            const langIndex = languages.findIndex((lan) => lan.language === language.language);
                                            if (langIndex > -1) {
                                                languages[langIndex].isUploaded = false;
                                            }
                                        }
                                    }
                                }
                                pm.languages = languages;
                                pm.instructionsEnabled = pm.instructionsEnabled ?? false;
                                pm.instructionUploaded = pm.instructionUploaded ?? false;
                                pm.feedbackEnabled = pm.feedbackEnabled ?? false;
                                pm.feedbackUploaded = pm.feedbackUploaded ?? false;
                            }
                            pm.examEnabled = pm.examEnabled ?? false;
                            return pm;
                        })
                    );
                }
            } catch (error) {
                console.log('There is an error while fetching content dashboard data', error);
            }
        }
        fetchData();
    }, [currentPage]);
    const goToContentConfiguration = (index) => {
        const record = rows[index];
        if (record && projectName) {
            navigate(`/${projectName}/content-configuration`, {
                state: {
                    drive: {
                        id: record.driveId,
                        name: record.driveName,
                    },
                    course: {
                        id: record.courseId,
                        name: record.courseName
                    },
                    configurationId: record?.['configurationId'] ?? null
                }
            });
        }
    }
    const goToContentUpload = (index) => {
        const record = rows[index];
        if (!record?.configurationId) {
            addNotification('Please configure content first.');
        } else if (record?.isLocked) {
            addNotification('Content is locked so can not be updated.')
        } else if (projectName) {
            navigate(`/${projectName}/upload`, {
                state: {
                    drive: {
                        id: record.driveId,
                        name: record.driveName,
                    },
                    course: {
                        id: record.courseId,
                        name: record.courseName
                    },
                    configurationId: record.configurationId,
                    activeTab: 'content',
                }
            });
        }
    }
    const goToInstructionsUpload = (index) => {
        const record = rows[index];
        if (!record?.configurationId) {
            addNotification('Please configure content first.');
        } else if (record?.isLocked) {
            addNotification('Content is locked so instructions can not be updated.')
        } else if (projectName) {
            navigate(`/${projectName}/upload`, {
                state: {
                    drive: {
                        id: record.driveId,
                        name: record.driveName,
                    },
                    course: {
                        id: record.courseId,
                        name: record.courseName
                    },
                    configurationId: record.configurationId,
                    activeTab: 'instructions'
                }
            });
        }
    }
    const goToFeedbackUpload = (index) => {
        const record = rows[index];
        if (!record?.configurationId) {
            addNotification('Please configure content first.');
        } else if (record?.isLocked) {
            addNotification('Content is locked so feedback can not be updated.')
        } else if (projectName) {
            navigate(`/${projectName}/upload`, {
                state: {
                    drive: {
                        id: record.driveId,
                        name: record.driveName,
                    },
                    course: {
                        id: record.courseId,
                        name: record.courseName
                    },
                    configurationId: record.configurationId,
                    activeTab: 'feedback'
                }
            });
        }
    }
    const handleLockExam = (pm) => {
        let needReturn = false;
        if (pm.instructionsEnabled && !pm.instructionsUploaded) {
            addNotification('Please upload instructions before locking content.');
            needReturn = true;
        }
        if (pm.feedbackEnabled && !pm.feedbackUploaded) {
            addNotification('Please upload feedback data before locking content.');
            needReturn = true;
        }
        if (needReturn) return;

        if (pm.configurationId && !pm.isLocked) {
            setLockExamModal({
                isVisible: true,
                configurationId: pm.configurationId,
            })
        }
    }
    const onLockExamModalClose = () => {
        setLockExamModal({
            isVisible: false,
            configurationId: null
        });
    }
    const onConfirmLockExam = async () => {
        if (lockExamModal.configurationId) {
            try {
                const res = await lockExam(lockExamModal.configurationId);
                if (res.code === 'OK') {
                    addNotification('Content locked successfully.', 'success');
                    const rowIndex = rows.findIndex((pm) => pm.configurationId === lockExamModal.configurationId);
                    if (rowIndex > -1) {
                        setRows(
                            rows.map((configuration, index) => {
                                if (index === rowIndex) {
                                    configuration.isLocked = true;
                                }
                                return configuration;
                            })
                        )
                    }
                    setLockExamModal({
                        isVisible: false,
                        configurationId: null
                    });
                }
            } catch (error) {
                console.log('There is an error while locking exam', error);
            }
        }
    }
    const handleExamEnable = (pm) => {
        if (pm.configurationId && projectName) {
            navigate(`/${projectName}/enable-exam`, {
                state: {
                    configurationId: pm.configurationId
                }
            });
        }
    }
    const renderTableCell = (columnId, rowIndex) => {
        const row = rows[rowIndex];
        switch (columnId) {
            case 'driveName':
                return row?.['driveName'];
            case 'courseName':
                return row?.['courseName'];
            case 'contentConfigured':
                return <Box component='span' sx={{
                    cursor: 'pointer',
                    color: row?.['contentConfigured'] ? 'success.main' : 'error.main',
                }} onClick={() => goToContentConfiguration(rowIndex)}>
                    {row?.['contentConfigured'] ? 'Yes' : 'No'}
                </Box>
            case 'contentUploaded':
                return <Box component='span' sx={{
                    cursor: 'pointer',
                    color: row?.['contentUploaded'] ? 'success.main' : 'error.main'
                }} onClick={() => goToContentUpload(rowIndex)}>
                    {row?.['contentUploaded'] ? 'Yes' : 'No'}
                </Box>;
            case 'languages':
                return <Box component='span' sx={{ cursor: 'default' }}>
                    {
                        row?.['languages']
                            ? row['languages'].map((language, index) => {
                                return <Box component='span' sx={{
                                    textTransform: 'capitalize',
                                    color: language.isUploaded ? 'success.main' : 'error.main'
                                }} key={index}>{`${language.language}`}{(index !== row['languages'].length - 1) ? ', ' : null}</Box>
                            })
                            : null
                    }
                </Box>
            case 'instructionUploaded':
                return <Box component='span' sx={{
                    cursor: 'pointer',
                    color: row?.['instructionsEnabled'] && row?.['instructionsUploaded'] ? 'success.main' : 'error.main'
                }} onClick={() => goToInstructionsUpload(rowIndex)}>
                    {row?.['instructionsEnabled']
                        ? row?.['instructionsUploaded'] ? 'Yes' : 'No'
                        : null}
                </Box>;
            case 'feedbackUploaded':
                return <Box component='span' sx={{
                    cursor: 'pointer',
                    color: row?.['feedbackEnabled'] && row?.['feedbackUploaded'] ? 'success.main' : 'error.main'
                }} onClick={() => goToFeedbackUpload(rowIndex)}>
                    {row?.['feedbackEnabled']
                        ? row?.['feedbackUploaded'] ? 'Yes' : 'No'
                        : null}
                </Box>;
            case 'isLocked':
                return <Box component='span' onClick={() => handleLockExam(row)} sx={{
                    cursor: 'pointer',
                    color: row?.['isLocked'] ? 'success.main' : 'error.main',
                }}>
                    {row?.['contentUploaded']
                        ? row?.['isLocked'] ? 'Yes' : 'No'
                        : null}
                </Box>;
            case 'examEnabled':
                return <Box component='span' sx={{
                    color: row?.['examEnabled'] ? 'success.main' : 'error.main',
                    cursor: 'default'
                }}>
                    {row?.['isLocked']
                        ? row?.['examEnabled'] ? 'Yes' : 'No'
                        : null}
                </Box>;
            default:
                return null;
        }
    }

    return (
        <MainContainer sx={{ bgcolor: 'red' }}>
            <Menu>
                <Breadcrumbs aria-label="breadcrumb" separator='' sx={{ gap: 12 }}>
                    <Typography color="text.primary" sx={{
                        fontWeight: 400,
                        textTransform: 'capitalize'
                    }}>{projectDetails?.projectName}</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW} alt=''></img>
                    </Box>

                    <Typography color="text.primary" sx={{ fontWeight: 500 }}>Content Dashboard</Typography>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <img src={ARROW_OPEN} alt='' ></img>
                    </Box>
                </Breadcrumbs>
            </Menu>

            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <h1 className='title'>Content Dashboard</h1>

                <Paper sx={{
                    width: '100%',
                    overflow: 'hidden',
                    bgcolor: 'background.base',
                    maxHeight: 'calc(100vh - 305px)',
                    maxWidth: 'calc(100vw - 140px)',
                    borderRadius: 1,
                    mb: 4
                }}>
                    <TableContainer sx={{
                        px: 1,
                        pb: 1,
                        maxHeight: 'inherit',
                        maxWidth: 'inherit',
                        border: '1px solid',
                        borderColor: 'primary.main',
                        borderRadius: 1
                    }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => {
                                        return <TableCell
                                            sx={{
                                                color: 'primary.main',
                                                px: 1.5
                                            }}
                                            key={column.id}
                                            align='center'
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, rowIndex) => {
                                    return (
                                        <TableRow hover role="checkbox" key={rowIndex}>
                                            {columns.map((column) => {
                                               return <TableCell key={column.id} align='center' sx={{
                                                    px: 1.5,
                                                    py: 2,
                                                }}>
                                                    {renderTableCell(column.id, rowIndex)}
                                                </TableCell>
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Pagination sx={{
                    ml: 'auto',
                    mt: 'auto'
                }} count={maxPages} page={currentPage} onChange={handleChange} shape="rounded" />

                <Modal open={lockExamModal.isVisible} hideBackdrop={true} className='backdrop' onClose={onLockExamModalClose} sx={{
                    maxWidth: '100vw',
                    overflow: 'auto'
                }}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.base',
                        boxShadow: 24,
                        borderRadius: 2,
                        width: 'auto',
                        p: 9,
                        textAlign: 'center',
                    }}>
                        <Typography variant='h6' sx={{
                            fontWeight: 400,
                            mb: 8
                        }}>
                            Are you sure you want to lock the content of this configuration?
                        </Typography>

                        <div className='actions-container' style={{ justifyContent: 'center', gap: '40px' }}>
                            <RadiusButton variant='outlined' onClick={onLockExamModalClose}>
                                No
                            </RadiusButton>
                            <RadiusButton variant='contained' onClick={onConfirmLockExam}>
                                Yes
                            </RadiusButton>
                        </div>
                    </Box>
                </Modal>

            </Container>

        </MainContainer>
    );
}