import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Modal, TextField, Typography } from "@mui/material";

import { useToaster } from "../../../../providers/ToasterProvider";
import { createCourse } from "../../../../services/courseService";

import RadiusButton from '../../../../components/RadiusButton';

const courseNameSchema = z.object({
    courseName: z.string().min(1, { message: 'Course name is required' }),
});


export const CourseDetailsModal = ({ openState, setOpenState }) => {

    const { addNotification } = useToaster();

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        reset,
    } = useForm({
        resolver: zodResolver(courseNameSchema),
    });

    const handleClose = () => {
        setOpenState(false);
        reset();
    };

    const onSubmit = async (data) => {
        try {
            const res = await createCourse(data);
            addNotification('Course created successfully', 'success');
            handleClose();
        } catch (error) {
            const code = error?.response?.data?.code;
            if (code === 'UNPROCESSABLE_ENTITY') {
                addNotification('Course already exists.', 'danger');
            } else {
                addNotification('Unexpected error occured', 'danger');
            }
            console.log('There is an error while creating course', error);
        }
        reset();
    }

    return (
        <Modal
            hideBackdrop={true}
            className='backdrop'
            open={openState}
            onClose={handleClose}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 480,
                bgcolor: 'background.base',
                boxShadow: 24,
                borderRadius: 2,
                p: 3,
            }}>
                <Typography variant="h5" component="h5" sx={{
                    mb: 2.5,
                    color: 'primary.main'
                }}>
                    Add Course
                </Typography>

                <Box component='form' sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }} onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        id='courseName'
                        name='courseName'
                        sx={{ width: '100%', maxWidth: 600 }}
                        label={
                            <>
                                <Box component='span'>Name</Box>
                                <Box component='span' sx={{ color: 'error.main', ml: 0.25 }}>*</Box>
                            </>
                        }
                        variant='outlined'
                        {...register('courseName')}
                        error={Boolean(errors.courseName)}
                        helperText={errors.courseName?.message}
                        onBlur={() => trigger('courseName')}
                    />

                    <Box className='actions-container' sx={{
                        mt: 3
                    }}>
                        <RadiusButton variant='outlined' sx={{ ml: 'auto' }} onClick={handleClose}>
                            Cancel
                        </RadiusButton>
                        <RadiusButton type='submit' variant='contained' >
                            Add
                        </RadiusButton>
                    </Box>
                </Box>

            </Box>
        </Modal>
    );
}
